











































import { Component, Vue } from "vue-property-decorator";
import BaseCard from "../Base/BaseCard.vue";
import BaseWrapper from "../Base/BaseWrapper.vue";

@Component({
    components: {
        BaseWrapper,
        BaseCard,
    },
})
export default class LandingStatistics extends Vue {
    public statistics: any[] = [
        { text: "Pengusul", value: 24, icon: "mdi-account-multiple" },
        { text: "Proposal", value: 63, icon: "mdi-clipboard-multiple" },
        { text: "Perusahaan", value: 14, icon: "mdi-domain" },
        {
            text: "Dana CSR Diberikan",
            value: "Rp. 163.000.000",
            icon: "mdi-cash-multiple",
        },
    ];

    public colors: string[] = ["primary", "success", "warning", "error"];
}
